

.app-illustration{
    transition: transform 0.5s ease;
}

.app-illustration:hover {
    animation: bounce 2s infinite;
}


@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

.swiper {
    width: 100% ;
    height: 250px ;
    text-align: left;
}
  
.swiper-slide div {
    display: block;
    width: 100% ;
    height: 100% ;
}

