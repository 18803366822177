.navbar {
    border: none;
    background-color: #FFFFFF !important
    ;
  }

.navbar-brand img {
    max-height: 40px;
}

.navbar-toggler {
    border: none;
}

.navbar-toggler:focus {
    outline: none;
}


  