/* Large font size */
.font-size-36 {
    font-size: calc(1.5em + 1vmin);
  }
  
/* Medium font size */
.font-size-24 {
    font-size: calc(0.9em + 1vmin);
}

/* Small font size */
.font-size-18 {
    font-size: calc(0.7em + 1vmin);
}

.raleway-bold{
    font-family: 'Raleway Bold', sans-serif !important;
}

.raleway-semibold{
    font-family: 'Raleway SemiBold', sans-serif !important;
}

.lato-bold{
    font-family: 'Lato Bold', sans-serif !important;
}

.lato-semi-bold{
    font-family: 'Lato SemiBold', sans-serif !important;
}

.text-input{
    background-color: transparent !important;
    border-bottom: 1px solid #8193E0 !important;
    outline: none; /* optional, removes the outline around the input field when it's clicked */
    border: none;
    width: 100%;
    color: #FFFFFF;
}

.circle {
    border-radius: 50%;
    color: black;
    height: 35px;
    width: 35px;
    font-weight: bold;
  }
  
  

  .client-squared-button{
    border: none;
    color: white;
    border-radius: 30px;
    width: 100%;
  }


/* mobile devices */
@media only screen and (max-width: 767px) { 
    .mobile-widget{
        display: block !important;
    }
    .desktop-widget{
        display: none !important;
    }
 
    #our-feature-section{
        border-radius: 25px 25px 0px 0px !important;
    }
    .swiper {
        width: 100% !important;
        height: 150px !important;
    }
    #top-app-illustration{
        height: 250px !important;
    }
    #white-card-illustration{
        height: 300px !important;
    }
    #cms-illustration{
        height: 300px !important;
    }
    #user-ui-illustration{
        height: 300px !important;
    }
    #height-container{
        height: 65vh !important;
    }
    #top-height-container{
        height: 100vh !important;
    }
    #image-height-container{
        height: 60vh !important;
    }
    /* Small font size */
    .font-size-18 {
        font-size: calc(0.8em + 1vmin);
    }   
    /* Medium font size */
    .font-size-24 {
        font-size: calc(1.2em + 1vmin);
    }

}

/* ipad devices */
@media (min-width: 768px) {
    .mobile-widget{
        display: block !important;
    }
    .desktop-widget{
        display: none !important;
    }
    .top-home-background-image-mobile {
        height: 60vw !important;
    }
    .top-home-background-image-mobile {
        height: 70vw !important;
    }
    .app-illustration{
        height: 350px !important;
    }
    #top-height-container{
        height: 50vh !important;
    }
    #footer-mobile-logo{
        width: 250px !important;
    }
    #top-app-illustration{
        height: 250px !important;
    }
    #white-card-illustration{
        height: 250px !important;
    }
    #cms-illustration{
        height: 250px !important;
    }
    #user-ui-illustration{
        height: 250px !important;
    }
    #height-container{
        height: 50vh !important;
    }
    #image-height-container{
        height: 50vh !important;
    }
    /* Small font size */
    .font-size-18 {
        font-size: calc(0.8em + 1vmin);
    }   
    
}

/* laptops */
@media (min-width: 992px) {
    .mobile-widget{
        display: none !important;
    }
    .desktop-widget{
        display: block !important;
    }
    #top-height-container{
        height: 85vh !important;
    }
    #height-container{
        height: 70vh !important;
    }
    #image-height-container{
        height: 70vh !important;
    }
    /* Small font size */
    .font-size-24 {
        font-size: calc(0.9em + 1vmin);
    }   
    .font-size-18 {
        font-size: calc(0.6em + 1vmin);
    }  
    
    #top-app-illustration{
        height: 350px !important;
    }
    #white-card-illustration{
        height: 350px !important;
    }
    #cms-illustration{
        height: 400px !important;
    }
    #user-ui-illustration{
        height: 400px !important;
    }
}
